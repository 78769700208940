html,
body,
#root {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.main-view-header {
    flex: 0 1 auto;
}

.main-view {
    width: 100%;
    height: 100%;
    display: flex;
    min-height: 0px;
    /* this disables overflow of child elements */
}

@media (min-width: 0px) and (max-width: 500px) {
    .main-view-relative {
        width: 100%;
        height: 100%;
    }
}

@media (min-width: 500px) {
    .main-view-relative {
        /* TODO: check this. This seems to be causing scrolling on mobile */
        position: relative;
        width: 100%;
        height: 100%;
    }
}

.main-view-relative-active {
    /* 10px space for ACTIVEFEED */
    margin-right: -10px;
}

.main-view-profile-setup-absolute {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: lightgray;
    opacity: 0.5;
}

.main-view-profile-setup {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
}

.main-view-side {
    margin-left: auto;
}

.main-view-footer {
    z-index: 100;
    height: 50px;
    /* box-shadow: 0px 0px 10px rgb(0 0 0 / 30%); */
    border-top: solid 1px lightgrey;
    align-items: center;
    display: grid;
}

.main-view-side-active {
    z-index: 1000;
}

.main-view-side-draft-mode-on {
    display: none;
}

.googlemap {
    width: 100%;
    height: 100%;
}


/* mobile */

@media (min-width: 0px) {
    /* .main-view-header {
        display: none;
    }
    .main-view-relative {
        width: 100%;
        height: 100%;
    }
    .main-view-side {
        display: flex;
        flex-direction: column;
    }
    .main-view-side-header {
        width: auto;
        height: 40px;
        background-color: yellow;
    }
    .main-view-side>.side-friend-list {
        flex: 1;
        background-color: whitesmoke;
        background-clip: content-box;
    }
    .main-view-side-footer {
        width: auto;
        height: 40px;
        background-color: whitesmoke;
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
    }
    .main-view-side-offset-padding {
        margin-left: 10px;
    } */
    .main-view-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        overflow-y: auto;
    }
    .googlemap-half {
        width: 100%;
        height: calc(30% + 23px);
        margin-bottom: -23px;
    }
    .left-imageViewer-show {
        width: 100%;
        height: 70%;
        display: block;
    }
    .main-view-info {
        display: flex;
        width: 100%;
        height: auto;
        background-color: whitesmoke;
        z-index: 1000;
    }
    .main-view-info-details {
        margin-top: auto;
        /* center horizontally */
        margin-bottom: auto;
        /* center horizontally */
        margin-left: 2px;
        margin-right: auto;
    }
    /* .main-view-footer {
        display: none;
    }
    .main-view-side-footer-control {
        position: absolute;
        bottom: 0px;
        right: 5px;
        height: 40px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }
    .main-view-side-footer-control:hover,
    .main-view-side-footer-control:active {
        border: 1px solid transparent;
        border-radius: 5px;
        background: #fff5f5;
        box-shadow: 0px 0px 2px 2px rgb(34 34 34 / 10%);
    }
    .main-view-side-footer-control:hover>.main-view-side-footer-add,
    .main-view-side-footer-control:active>.main-view-side-footer-add {
        background: #fff5f5;
        transform: rotate(-45deg);
        box-shadow: none;
    }
    .main-view-side-footer-control:hover>.main-view-side-footer-action-button,
    .main-view-side-footer-control:active>.main-view-side-footer-action-button {
        visibility: visible;
        opacity: 1;
        width: auto;
    } */
}

.main-view-side-footer-add {
    border: 1px solid transparent;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    box-shadow: 0px 0px 2px 2px rgb(34 34 34 / 10%);
    cursor: pointer;
    transition: transform 0.3s;
}

.main-view-side-footer-add:before,
.main-view-side-footer-add:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.main-view-side-footer-add:before,
.main-view-side-footer-add:after {
    background: #f7c29e;
}

.main-view-side-footer-add:before {
    width: 10%;
    margin: 15% auto;
}

.main-view-side-footer-add:after {
    margin: auto 15%;
    height: 10%;
}

.main-view-side-footer-action-button {
    visibility: hidden;
    opacity: 0;
    width: 0;
}


/* desktop: left right */

@media (min-width: 768px) {
    .main-view-header {
        display: block;
        flex: 0 1 auto;
    }
    .main-view-relative {
        /* TODO: check this. This seems to be causing scrolling on mobile */
        position: relative;
        width: 100%;
        height: 100%;
    }
    .main-view-side {
        margin-left: auto;
    }
    .main-view-side-header,
    .main-view-side-footer {
        display: none;
    }
    .main-view-content {
        height: 100%;
        display: flex;
        flex-direction: row;
        flex: 1 1 auto;
        overflow-y: auto;
    }
    .googlemap-half {
        width: 30%;
        height: 100%;
    }
    .left-imageViewer-show {
        width: 70%;
        height: calc(100% - 65px);
    }
    .main-view-info {
        border-radius: 15px;
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        width: 70%;
        height: 65px;
        background-color: whitesmoke;
        z-index: 1000;
        overflow: hidden;
    }
    .main-view-info-details {
        margin-top: auto;
        /* center horizontally */
        margin-bottom: auto;
        /* center horizontally */
        margin-left: 8px;
        margin-right: auto;
    }
    .main-view-footer {
        z-index: 100;
        height: 50px;
        border-top: solid 1px lightgrey;
        align-items: center;
        display: grid;
    }
    /* .main-view-side-footer-control {
        display: none;
    } */
}

.left-imageViewer-hide {
    display: none;
}

.main-view-info-details-row {
    display: flex;
    margin-top: 3px;
    margin-bottom: 3px;
}

.main-view-info-details>p {
    margin: 0px;
}

.main-view-info-details-icon {
    margin-right: 3px;
}

.main-view-info-liked-by {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
}

.main-view-info-liked-by>p {
    margin-top: auto;
    margin-bottom: auto;
}

.main-view-info-liked-by-profile-photo-wrapper:not(:first-child) {
    margin-left: -18px;
}

.main-view-info-liked-by-profile-photo {
    height: 40px;
    width: 40px;
    border: white solid 2px;
    border-radius: 50%;
}

.main-view-info-like {
    margin-left: 0px;
    /* 10px space for ACTIVEFEED */
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
    height: 40px;
    width: 40px;
    cursor: pointer;
}

.main-view-info-like>svg {
    height: 40px;
    width: 40px;
}

.main-view-info-like-true {
    fill: url(#heart-liked-gradient) #FF0000;
    filter: drop-shadow(0px 1px 2px #CC0000);
}

#heart-liked-gradient {
    --color-stop: #D31616;
    --color-bot: #FF0000;
}

.main-view-info-like-false {
    fill: #bdbdbd;
    filter: drop-shadow(0px 3px 2px #bdbdbd);
}

.side-hidden {
    display: none;
}

.info-window-img {
    border-radius: 5px;
    border: 1px solid white;
    box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%);
}

.triagle {
    height: 10px;
    width: 10px;
    margin: auto;
    display: block;
    transform: translate(0%, -50%) rotate(-45deg);
    box-shadow: -2px 2px 2px 0 rgb(178 178 178 / 40%);
}

.main-view-footer-list {
    display: flex;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    height: 100%;
}

.main-view-footer-item {
    display: flex;
    align-items: center;
    list-style: none;
    cursor: pointer;
    border-radius: 5px;
    width: 70px;
}

.main-view-footer-item:hover {
    box-shadow: 0px 0px 5px rgb(0 0 0 / 30%);
}

.main-view-footer-item-active {
    /* border-top-style: solid;
    border-top-width: 2px;
    border-top-color: black; */
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.main-view-footer-icons {
    margin: auto;
}

.main-view-footer-item-active>.main-view-footer-icons {
    height: 30px;
    width: 30px;
}

.test-buttons {
    width: 150px;
    opacity: 0.7;
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 100px;
    margin-bottom: 10px;
    overflow-y: scroll;
}

.experiment-footer-control {
    background-color: lightblue;
    border-radius: 50%;
    box-shadow: 0 0 4px 5px rgb(0 0 0 / 20%);
    height: 100px;
    width: 100px;
    position: absolute;
    bottom: -50px;
    right: -50px;
    z-index: 1;
}

.experiment-footer-control:hover>.experiment-footer-add,
.experiment-footer-control:active>.experiment-footer-add {
    transform: rotate(-45deg);
    box-shadow: none;
}

.experiment-footer-add {
    cursor: pointer;
    position: relative;
    height: 40px;
    width: 40px;
    left: 11%;
    top: 11%;
    transition: transform 0.3s;
}

.experiment-footer-add:before,
.experiment-footer-add:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.experiment-footer-add:before,
.experiment-footer-add:after {
    background: #f7c29e;
}

.experiment-footer-add:before {
    width: 10%;
    margin: 15% auto;
}

.experiment-footer-add:after {
    margin: auto 15%;
    height: 10%;
}

.experiment-footer-pie {
    border-radius: 50%;
    box-shadow: 0 0 4px 5px rgba(0, 0, 0, 0.2);
    height: 400px;
    width: 400px;
    position: absolute;
    right: -200px;
    bottom: -200px;
    transform: translateX(200px) translateY(200px);
    transition: transform 300ms;
    cursor: pointer;
}

.experiment-footer-pie1 {
    clip-path: polygon(200px 200px, 0px 0px, 0 200px);
    transition-delay: 100ms;
}

.experiment-footer-pie2 {
    clip-path: polygon(200px 200px, 0px 0px, 200px 0px);
    transition-delay: 50ms;
}

.experiment-footer-pie-color {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.experiment-footer-pie-color1 {
    background: linear-gradient(135deg, #f12711, #f5af19);
    clip-path: polygon(200px 200px, 0px 0px, 0 200px);
}

.experiment-footer-pie-color2 {
    background: linear-gradient(135deg, #f5af19, #f12711);
    clip-path: polygon(200px 200px, 0px 0px, 200px 0px);
}

.experiment-footer-control:hover~.experiment-footer-pie,
.experiment-footer-control:active~.experiment-footer-pie {
    transform: translateX(0) translateY(0);
}

.experiment-footer-icon-add-photo {
    position: absolute;
    left: 29px;
    top: 117px;
    height: 50px;
    width: 50px;
}

.experiment-footer-icon-add-friend {
    position: absolute;
    left: 117px;
    top: 30px;
    height: 50px;
    width: 50px;
}

.route-interaction {
    display: flex;
    justify-content: space-between;
}

.route-interaction-add-comment {
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.route-interaction-add-comment>.form-control {
    border-radius: 1rem;
}

.route-interaction-info {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
}

.route-interaction-comments {
    display: flex;
    margin-right: 10px;
}

.route-interaction-likes {
    display: flex;
}

.route-interaction-comments-icon,
.route-interaction-likes-icon {
    display: flex;
    align-items: center;
    margin-right: 2px;
}
.side-friend-feed {
    height: 100%;
}

.side-friend-list {
    display: flex;
    flex-direction: column;
    min-width: 40px;
    padding-left: 0px;
    overflow: auto;
    height: 100%;
}

.side-friend-list-active {
    /* 10px space for ACTIVEFEED */
    padding-left: 10px;
}

.side-friend-list-load-more-short,
.side-friend-list-load-more-full {
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    color: gray;
    cursor: pointer;
}

@media (min-width: 0px) and (max-width: 500px) {
    .side-friend-list-load-more-short {
        display: inline-block;
    }
    .side-friend-list-load-more-full {
        display: none;
    }
}

@media (min-width: 500px) {
    .side-friend-list-load-more-full {
        display: inline-block;
    }
    .side-friend-list-load-more-short {
        display: none;
    }
}

.side-friend-li {
    background-color: #ecf1f7;
    padding-right: 5px;
    cursor: pointer;
    display: block;
    border-top: 1px solid lightgray;
}

.side-friend-li-active {
    /* 10px space for ACTIVEFEED */
    margin-left: -10px;
    padding-left: 5px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-left: solid gray 1px;
    border-top: solid gray 1px;
    border-bottom: solid gray 1px;
}

.side-friend-li:hover {
    background-color: #d7e3f1;
}

.side-friend {
    display: flex;
}

.side-friend-active {
    margin-left: -5px;
    transition: margin-left 100ms;
}

@media (min-width: 0px) and (max-width: 500px) {
    .side-friend>.side-friend-avatar {
        width: 40px;
        height: 40px;
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .side-friend>.side-friend-avatar-active {
        width: 44px !important;
        height: 44px !important;
    }
}

@media (min-width: 500px) {
    .side-friend>.side-friend-avatar {
        width: 48px;
        height: 48px;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    /* when showing full info, dont enlarge photo, because it will shift text down. */
}

.side-friend>.side-friend-avatar {
    border-radius: 50%;
    box-shadow: -1px 1px 11px 6px rgba(189, 172, 172, 0.33);
    display: flex;
    justify-content: center;
    align-items: center;
}

.side-friend-avatar>svg {
    width: 35px;
    height: 35px;
}

.side-friend-details {
    align-items: center;
    display: flex;
    white-space: nowrap;
}

.side-friend-name {
    font-weight: bold;
    margin-bottom: 0px;
}

.side-friend-profile-avatar {
    /* TODO: need to set either height or width to 100% */
    width: 100%;
}

@media screen and (min-width: 0px) and (max-width: 500px) {
    .side-friend-details {
        display: none;
    }
    /* show it on small screens */
}

@media screen and (min-width: 500px) and (max-width: 1024px) {
    .side-friend-detailst {
        display: flex;
    }
    /* hide it elsewhere */
}

.side-friend-item-enter {
    opacity: 0.01;
}

.side-friend-item-enter.side-friend-item-enter-active {
    opacity: 1;
    transition: all 500ms ease-in;
}
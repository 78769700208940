.draft-view {
    /* remove above lines */
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 100;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    /* allow map to be shown in the rounded corners */
    margin-bottom: -10px;
    box-shadow: 0px 0px 10px black;
    background-color: whitesmoke;
}

.draft-view-header {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    background-color: lightgray;
}

.draft-view-info {
    padding: 5px;
}

.draft-view-header-discard {
    margin-top: auto;
    margin-bottom: auto;
}

.draft-view-header-title {
    margin: auto;
}

.draft-view-row {
    display: flex;
    margin-top: 8px;
    margin-bottom: 5px;
}

.draft-view-col {
    display: flex;
}

.draft-view-icon {
    margin: auto;
}
.image-viewer-original-image {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    user-select: none;
}

.image-viewer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    background-color: rgb(34 34 34);
    position: relative;
    padding-bottom: 5px;
}

.image-viewer-timestamp {
    margin: 2px auto;
    color: whitesmoke;
}

.image-viewer-header {
    display: flex;
    height: 30px;
    width: 100%;
}

.image-viewer-header-no-content {
    height: 5px;
    width: 100%;
}

.image-viewer-location {
    display: flex;
    color: white;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}

.image-viewer-location-icon {
    fill: white;
}

.image-viewer-select {
    border-radius: 4px;
    padding-left: 5px;
    padding-right: 5px;
}

.image-viewer-select:focus {
    outline: thin dotted;
}

.image-viewer-close-button {
    position: absolute;
    top: 2px;
    left: 2px;
}

.image-viewer-left-button {
    position: absolute;
    left: 2px;
    top: 49%;
    display: block;
}

.image-viewer-right-button {
    position: absolute;
    right: 2px;
    z-index: 1001;
    top: 49%;
    display: block;
}

.image-viewer-left-button>svg,
.image-viewer-right-button>svg {
    fill: white;
    width: 30px;
    height: 30px;
}

.image-viewer-left-button:hover>svg,
.image-viewer-right-button:hover>svg {
    fill: white;
    cursor: pointer;
    stroke: white;
    stroke-width: 1px;
}
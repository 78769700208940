@media (min-width: 0px) and (max-width: 500px) {
    .search-friend-container {
        width: 85%;
    }
}

@media (min-width: 500px) {
    .search-friend-container {
        width: 70%;
    }
}

.search-friend-container {
    position: absolute;
    max-width: 750px;
    top: 20%;
    left: 50%;
    transform: translate(-50%, 0%);
    border-radius: 8px;
    box-shadow: 5px 5px 10px lightgray;
    background-color: whitesmoke;
}

.search-friend-container:focus-within {
    outline: none;
    box-shadow: 5px 5px 10px grey;
}

.search-friend-search-bar {
    position: relative;
    display: flex;
    align-items: center;
}

.search-friend-search-bar-input {
    width: 100%;
    padding: 10px 50px 10px 20px;
    border-radius: 5px;
    border: none;
    font-size: 24px;
}

.search-friend-search-bar-input:focus {
    outline: none;
}

.search-friend-search-bar-icon-area {
    position: absolute;
    display: flex;
    height: 100%;
    width: 60px;
    right: 0px;
    margin: auto;
    cursor: pointer;
}

.search-friend-search-bar-icon {
    margin: auto;
}

.search-friend-result {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 5px;
}

.search-friend-reaction-buttons {
    margin: 0 2px;
}

.search-friend-profile-avatar-container {
    width: 100px;
    height: 100px;
    border: white 1px solid;
    border-radius: 50%;
    box-shadow: -1px 1px 11px 6px rgba(189, 172, 172, 0.33);
    padding: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: auto;
    margin-right: auto;
    /* margin-left: 10px;
    margin-right: 10px; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.search-friend-profile-avatar {
    width: 100%;
    height: 100%;
}
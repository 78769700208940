.login-register-form-label {
    font-size: 14px;
    color: grey;
}

.form-register-dob {
    display: flex;
}

.form-register-dob>svg {
    margin: auto 0 auto 5px;
    cursor: help;
}
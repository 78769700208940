.landingPage {
    background-color: #ff640010;
    height: 100%;
    width: 100%;
}

.loading-animation {
    height: 80%;
    width: 80%;
    top: 10%;
    left: 10%;
    position: absolute;
}

.loading-animation svg {
    height: 100%;
    width: 100%;
}

.loading-animation path {
    stroke-dasharray: 600 600;
    stroke-dashoffset: 600;
    /* animation: dash 4s ease-out forwards; */
}

.loading-animation-active {
    animation: dash 2s ease-out forwards;
}

@keyframes dash {
    from {
        stroke-dasharray: 600 600;
        stroke-dashoffset: 600;
    }
    to {
        stroke-dasharray: 600 600;
        stroke-dashoffset: 0;
    }
}

.circle-loader {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-left-color: #5cb85c;
    animation: loader-spin 1.2s infinite linear;
    vertical-align: top;
    border-radius: 50%;
    width: 7em;
    height: 7em;
}
.marker-thumbnail-btn {
    position: absolute;
    right: -6px;
    top: -6px;
}

.marker-thumbnail-btn>.btn-close {
    border: solid white 1px;
    border-radius: 50%;
    background-color: white;
    opacity: 0.9;
    background-size: 9px;
    height: 9px;
    width: 9px;
    padding: 2px;
}

.marker-thumbnail-btn>.btn-close:hover {
    opacity: 1;
}

.marker-thumbnail-btn-delete {}
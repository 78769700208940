.profile-setup {
    position: absolute;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 5px 10px 0px 10px;
    background-color: whitesmoke;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 750px;
    z-index: 1100;
}

.profile-avatar-container {
    width: 100px;
    height: 100px;
    border: white 1px solid;
    border-radius: 50%;
    box-shadow: -1px 1px 11px 6px rgba(189, 172, 172, 0.33);
    padding: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

@media (min-width: 0px) and (max-width: 576px) {
    .profile-avatar-container {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 576px) {
    .profile-avatar-container {
        margin-left: 10px;
    }
}

.profile-avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.exclamation-circle {
    margin: auto 0 auto 10px;
    cursor: help;
}

.profile-alert {
    padding-left: 20px;
    padding-right: 20px;
}

.submit-row {
    display: flex !important;
    align-items: center;
}

.submit-row-button {
    background-color: #f7c29e;
    color: rgba(34 34 34 / 0.9);
    box-shadow: 0px 0px 4px rgb(189 172 172 / 50%);
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.submit-row-button:disabled {
    pointer-events: none;
    opacity: .65;
}

@media (min-width: 0px) and (max-width: 575px) {
    .submit-row-button {
        margin-left: auto;
        margin-right: auto;
    }
}

.submitting {
    margin: auto 0 auto 10px;
    width: 30px !important;
    height: 30px !important;
}

.checkmark {
    margin: auto 0 auto 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    stroke-width: 3;
    stroke: #fff;
    box-shadow: inset 0px 0px 0px #28a745;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #28a745;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%,
    100% {
        transform: none;
    }
    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #28a745;
    }
}

.profile-setup-close-button {
    position: absolute;
    top: 5px;
    right: 5px;
}

.profile-setup-validation-error {
    color: red
}

.profile-setup-change-photo {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    box-shadow: 0px 1px 3px rgb(189 172 172 / 80%);
    fill: rgba(34 34 34 / 0.6);
    background-color: #f7c29e;
}
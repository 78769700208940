.test-bound {
    position: absolute;
    top: 50px;
    left: 50px;
}

.info-window-img {
    border-radius: 5px;
    border: 1px solid white;
    box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%);
}

.triagle {
    height: 10px;
    width: 10px;
    margin: auto;
    display: block;
    transform: translate(0%, -50%) rotate(-45deg);
    box-shadow: -2px 2px 2px 0 rgb(178 178 178 / 40%);
}

.test-body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.test-main {
    background-color: red;
    width: 50%;
}

.test-list {
    display: flex;
    flex-direction: column;
    height: 300px;
    /* width: 80px; */
    background-color: lightgoldenrodyellow;
    overflow-y: scroll;
}

.test-list-item {
    margin-top: 5px;
    margin-bottom: 5px;
    border: black solid 3px;
}

.test-list-item-active {
    position: relative;
}

.test-image-wrapper {
    width: 250px;
    height: 200px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: auto;
    margin-right: auto;
    border: solid black 1px;
}

.test-image-img {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}